<template>
  <div class='mt-2 lg:mt-12'>
    <div class='flex flex-row justify-end gap-x-1 mb-4 lg:mb-12 text-sm px-2 lg:px-0'>
      <file-downloader
        v-for='(file, index) in eventFileListOnPrograms'
        :key='`file-${index}`'
        :file='file'/>
    </div>
    <div v-if='eventProgramsPageMessage' v-html='eventProgramsPageMessage' class='mb-8'></div>
    <component
      :is='eventBasedProgramSchedule'
      :default-to-today='defaultToToday'
      :show-schedule-full-width='showScheduleFullWidth'
      :program-slot-size='programSlotSize' 
      class='px-2 lg:px-0'  />
    <sponsors-carousel-from-config :carousel-sponsors='eventConfigProgramsCarouselSponsors' />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import FileDownloader             from '@/components/FileDownloader.vue'
import SponsorsCarouselFromConfig from '@/components/SponsorsCarouselFromConfig.vue'

export default {
  name: 'Programs',
  components: {
    'program-schedule': () => import('@/components/programs/ProgramSchedule.vue'),
    'program-schedule-icola': () => import('@/components/icola2023/ProgramSchedule.vue'),
    FileDownloader,
    SponsorsCarouselFromConfig,
  },
  props: [
    'defaultToToday',
    'needToBeLoggedIn',
    'showScheduleFullWidth',
    'programSlotSize',
  ],
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventFileListOnPrograms',
      'eventProgramsPageMessage',
      'eventConfigProgramsCarouselSponsors',
      'showingEventId',
    ]),
    eventBasedProgramSchedule() {
      return this.showingEventId === 32 ? 'program-schedule-icola' : 'program-schedule'
    },
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('sessions', [
      'getSessions',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.needToBeLoggedIn) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'Programs'} })
      }
    })
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    this.getSessions().then(() => {
      this.hideFullpageLoadingIndicator()
    })
  },
}
</script>
