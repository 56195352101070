<template>
  <a class='border border-gray-200 px-3 py-1 lg:py-2 text-white font-medium'
     :style='`background-color: ${eventMainThemeColor} ;`' 
     :href='file.url'
     target='_blank'>
     {{file.label}} Download
  </a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FileDownloader',
  props: ['file'],
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor'
    ])
  }
}
</script>